import './Footer.css';
import React from 'react';

function Footer() {
    return (
        <div>
            <hr />
            <p className="display-6">petartotev.net</p>
            <img src='logo192.png' alt="logo" width='64px' height='64px' className="mb-3"/>
            <h3>{new Date().getFullYear()}</h3>
        </div>
    );
}

export default Footer;