import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ConfirmModal = ({ show, onClose, onConfirm, title, message, ico, color }) => {
  if (!show) {
    return null;
  }

  const modalHeaderClassName = "modal-header d-flex justify-content-center " + (color === undefined || color === null || color === '' ? "bg-warning" : color);

  return (
    <div className="modal text-dark" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          {/*<div className="modal-header bg-warning">*/}
          <div className={modalHeaderClassName}>
            {/*<h5 className="modal-title">{title}</h5>*/}
            <FontAwesomeIcon icon={ico} size="4x" />
          </div>
          <div className="modal-body">
            <p className="display-6">{message}</p>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button onClick={onClose} className="btn btn-sm btn-light rounded-pill"><FontAwesomeIcon icon="fa-backward-step" size="3x" /></button>
            <button onClick={onConfirm} className="btn btn-sm btn-light rounded-pill"><FontAwesomeIcon icon={ico} size="3x" /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;