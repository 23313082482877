import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Login({ onLogin, onCancelLogin, onForgetPassword }) {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [userLoginData, setUserLoginData] = useState({
        email: '',
        password: ''
    });

    const [errors, setErrors] = useState({
        email: '',
        password: ''
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setUserLoginData({ ...userLoginData, [id]: value });
        validateField(id, value);
    };

    const handleBlur = (e) => {
        const { id, value } = e.target;
        validateField(id, value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            onLogin(userLoginData);
        } else {
            // Show errors for all fields
            validateField('email', userLoginData.email);
            validateField('password', userLoginData.password);
        }
    };

    const validateField = (fieldId, value) => {
        let errorMessage = '';

        switch (fieldId) {
            case 'email':
                if (!value.trim()) {
                    errorMessage = 'Email is required';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    errorMessage = 'Email is invalid';
                }
                break;
            case 'password':
                if (!value) {
                    errorMessage = 'Password is required';
                } else if (!/(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}/.test(value)) {
                    errorMessage = 'Password must be at least 10 characters (incl. letter(s), number(s) and symbol(s))';
                }
                break;
            default:
                break;
        }

        setErrors({ ...errors, [fieldId]: errorMessage });
    };

    const isFormValid = () => {
        return Object.values(errors).every(error => !error);
    };

    return (
        <div className="card bg-light text-dark text-center mb-3 mt-3">
            <div className="card-header">
                <button title="cancel" onClick={onCancelLogin} className="btn rounded-pill"><h1 className="display-6">LOG IN</h1></button>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            value={userLoginData.email}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            required
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <div className="input-group mb-3">
                            <button type="button" onClick={togglePasswordVisibility} className="btn btn-outline-secondary">
                                <FontAwesomeIcon icon={showPassword ? "fa-eye-slash" : "fa-eye"} size="1x" />
                            </button>
                            <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                id="password"
                                value={userLoginData.password}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                required
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                        </div>
                    </div>
                    <div className="mb-3 d-flex justify-content-end">
                        <button title="forget-password" type="button" onClick={onForgetPassword} className="btn btn-sm btn-dark text-light rounded-pill">Forgot Password</button>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <button title="cancel" type="button" onClick={onCancelLogin} className="btn btn-sm rounded-pill">&nbsp;<FontAwesomeIcon icon="fa-backward-step" size="3x" />&nbsp;</button>
                            <button title="submit" type="submit" className="btn btn-sm rounded-pill" disabled={!isFormValid()}>&nbsp;<FontAwesomeIcon icon="fa-paper-plane" size="3x" />&nbsp;</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;