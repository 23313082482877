import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PasswordForget({ onCancelForgetPassword, onSendForgetPassword }) {

    const [userForgetData, setUserForgetData] = useState({
        email: ''
    });

    const [errors, setErrors] = useState({
        email: ''
    });

    const isFormValid = () => {
        return Object.values(errors).every(error => !error);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setUserForgetData({ ...userForgetData, [id]: value });
        validateField(id, value);
    };

    const handleBlur = (e) => {
        const { id, value } = e.target;
        validateField(id, value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            onSendForgetPassword(userForgetData);
        } else {
            // Show errors for all fields
            validateField('email', userForgetData.email);
        }
    };

    const validateField = (fieldId, value) => {
        let errorMessage = '';

        switch (fieldId) {
            case 'email':
                if (!value.trim()) {
                    errorMessage = 'Email is required';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    errorMessage = 'Email is invalid';
                }
                break;
            default:
                break;
        }

        setErrors({ ...errors, [fieldId]: errorMessage });
    };

    return (
        <div className="card bg-light text-dark text-center mb-3 mt-3">
            <div className="card-header">
                <h1 className="display-6">FORGOT PASSWORD</h1>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            value={userForgetData.email}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            required
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <button title="cancel" type="button" onClick={onCancelForgetPassword} className="btn btn-sm rounded-pill">&nbsp;<FontAwesomeIcon icon="fa-backward-step" size="3x" />&nbsp;</button>
                            <button title="submit" type="submit" className="btn btn-sm rounded-pill" disabled={!isFormValid()}>&nbsp;<FontAwesomeIcon icon="fa-paper-plane" size="3x" />&nbsp;</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PasswordForget;