import './Notebook.css';
import React, { useEffect, useState } from 'react';
import { getAllNotes, updateNoteStatusById, updateNoteArchiveById, deleteNoteById, postNote, updateNoteById } from '../../clients/note-app-server.js';
import NoteBox from '../NoteBox/NoteBox.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoteCreateForm from '../NoteCreateForm/NoteCreateForm.js';

function Notebook({ authToken, calculateNotesCount, onJwtExpired }) {
    const [notes, setNotes] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showArchived, setShowArchived] = useState(false);

    async function takeActionIfJwtExpired(error) {
        try {
            if (error !== undefined && error !== null
                && error.response !== undefined && error.response !== null
                && error.response.data !== undefined && error.response.data !== null
                && error.response.data.error !== undefined && error.response.data.error !== null && !error.response.data.error !== ''
                && error.response.data.error.toLowerCase().includes("jwt")) {
                    onJwtExpired('User sessions are active for 1 hour. Please log in again!');
                }
        } catch (err) {
            console.error('Error while taking action on expired JWT token:', err)
        }
    }

    async function updateNoteStatus(id, status) {
        try {
            if (status === 'DELETE!') {
                await deleteNoteById(id, authToken);
            } else {
                await updateNoteStatusById(id, status, authToken);
            }
            
            const updatedNotes = await getAllNotes(authToken);
            setNotes(updatedNotes.data); // Update the notes state with the updated list
            calculateNotesCount(updatedNotes.data !== null && updatedNotes.data !== undefined && updatedNotes.data.length !== 0 ? updatedNotes.data.length : 0);
        } catch (error) {
            console.error('Error updating note:', error);
            takeActionIfJwtExpired(error);
        }
    }

    async function updateNoteArchive(id, isArchived) {
        try {
            await updateNoteArchiveById(id, isArchived, authToken);
            
            const updatedNotes = await getAllNotes(authToken);
            setNotes(updatedNotes.data); // Update the notes state with the updated list
            calculateNotesCount(updatedNotes.data !== null && updatedNotes.data !== undefined && updatedNotes.data.length !== 0 ? updatedNotes.data.length : 0);
        } catch (error) {
            console.error('Error archiving note:', error);
            takeActionIfJwtExpired(error);
        }
    }

    async function updateNote(noteToUpdate) {
        try {
            await updateNoteById(noteToUpdate.id, noteToUpdate, authToken);
            const updatedNotes = await getAllNotes(authToken);
            setNotes(updatedNotes.data); // Update the notes state with the updated list
            calculateNotesCount(updatedNotes.data !== null && updatedNotes.data !== undefined && updatedNotes.data.length !== 0 ? updatedNotes.data.length : 0);
        } catch (error) {
            console.error('Error updating note:', error);
            takeActionIfJwtExpired(error);
        }
    }

    const handleCreateNoteSubmit = async (noteData) => {
        // Implement note creation logic here
        // For example, using the postNote function you will create next
        try {
            if (noteData.status === null || noteData.status === undefined) {
                noteData.status = "todo";
            }
            await postNote(noteData, authToken);
            const updatedNotes = await getAllNotes(authToken);
            setNotes(updatedNotes.data);
            calculateNotesCount(updatedNotes.data !== null && updatedNotes.data !== undefined && updatedNotes.data.length !== 0 ? updatedNotes.data.length : 0);
            setShowForm(false); // Hide form on successful creation
        } catch (error) {
            console.error('Error creating note:', error);
            takeActionIfJwtExpired(error);
        }
    };

    function handleCreateNoteCancel() {
        setShowForm(false);   
    }

    const toggleArchivedVisibility = () => setShowArchived(!showArchived);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const response = await getAllNotes(authToken);
                setNotes(response.data);
                calculateNotesCount(response.data !== null && response.data !== undefined && response.data.length !== 0 ? response.data.length : 0);
            } catch (error) {
                console.error('Error fetching notes:', error);
                takeActionIfJwtExpired(error);
            }
        };
        if (authToken) fetchNotes(); // Only fetch notes if token is set
    }, [authToken]); // Re-fetch notes when token changes

    if (notes === null) {
      return <div>Loading...</div>; // Handle loading state
    }
  
    const sections = {
        TODO: notes.filter(note => note.isArchived === 0 && note.status === 'todo'),
        DOING: notes.filter(note => note.isArchived === 0 && note.status === 'doing'),
        ON_HOLD: notes.filter(note => note.isArchived === 0 && note.status === 'on_hold'),
        NOT_DOING: notes.filter(note => note.isArchived === 0 && note.status === 'not_doing'),
        DONE: notes.filter(note => note.isArchived === 0 && note.status === 'done')
    };

    return (
        <div>
            {!showForm && <div><hr /><button title="create" className="btn btn-dark rounded-pill" onClick={() => setShowForm(!showForm)}><FontAwesomeIcon icon="fa-plus" size="5x" /></button></div>}
            {showForm && <NoteCreateForm onCreate={handleCreateNoteSubmit} cancelCreate={handleCreateNoteCancel} />}
            {notes !== null && Object.entries(sections).map(([status, sectionNotes]) => (
                <div key={status}>
                    <hr />
                    <h2 className="display-3">{status}</h2>
                    {sectionNotes.map(note => (<NoteBox key={note.id} note={note} updateMyStatus={updateNoteStatus} updateMyArchive={updateNoteArchive} onUpdate={updateNote}/>))}
                </div>
            ))}
            <div key="ARCHIVED">
                <hr />
                <h2 className="display-3">ARCHIVED</h2>
                {showArchived && notes.filter(note => note.isArchived === 1).map(note => (<NoteBox key={note.id} note={note} updateMyStatus={updateNoteStatus} updateMyArchive={updateNoteArchive} />))}
            </div>
            <div className="d-flex justify-content-center">
                <div className="form-check form-switch fs-4">
                    <input className="form-check-input" type="checkbox" id="archivedToggle" checked={showArchived} onChange={toggleArchivedVisibility} role="switch" />
                </div>
            </div>
        </div>
    );
}

export default Notebook;