// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-profile-header {
    background-color: rgb(180, 185, 190);
}

.card-profile-body {
    background-color: rgb(210, 215, 220);
}

.card-profile-footer {
    background-color: rgb(180, 185, 190);
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/Profile.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".card-profile-header {\n    background-color: rgb(180, 185, 190);\n}\n\n.card-profile-body {\n    background-color: rgb(210, 215, 220);\n}\n\n.card-profile-footer {\n    background-color: rgb(180, 185, 190);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
