import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PasswordReset({ onResetPassword, onCancelResetPassword }) {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirmed, setShowPasswordConfirmed] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordConfirmedVisibility = () => {
        setShowPasswordConfirmed(!showPasswordConfirmed);
    };

    const [userResetData, setUserResetData] = useState({
        email: '',
        newPassword: '',
        newPasswordConfirmed: '',
        passwordToken: ''
    });

    const [errors, setErrors] = useState({
        email: '',
        newPassword: '',
        newPasswordConfirmed: '',
        passwordToken: ''
    });

    const handleInputChange = (e) => {
        setUserResetData({ ...userResetData, [e.target.id]: e.target.value });
    };

    const handleBlur = (e) => {
        const { id, value } = e.target;
        validateField(id, value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            onResetPassword(userResetData);
        }
    };

    const validateField = (fieldId, value) => {
        let errorMessage = '';

        switch (fieldId) {
            case 'email':
                if (!value.trim()) {
                    errorMessage = 'Email is required';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    errorMessage = 'Email is invalid';
                }
                break;
            case 'newPassword':
                const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?.&])[A-Za-z\d@$!%*?.&]{10,}$/;
                if (!value) {
                    errorMessage = 'New password is required';
                } else if (!passwordRegex.test(value)) {
                    errorMessage = 'New password must be at least 10 characters (incl. letter(s), number(s) and symbol(s))';
                }
                break;
            case 'newPasswordConfirmed':
                if (value !== userResetData.newPassword) {
                    errorMessage = 'Passwords do not match';
                }
                break;
            case 'passwordToken':
                if (!value.trim()) {
                    errorMessage = 'Password Token is required';
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    errorMessage = 'Password Token must be alphanumeric';
                }
                break;
            default:
                break;
        }

        setErrors({ ...errors, [fieldId]: errorMessage });
    };

    const isFormValid = () => {
        return Object.values(errors).every(error => !error);
    };

    return (
        <div className="card bg-secondary text-center mb-3 mt-3">
            <div className="card-header">
                <h1 className="display-6">RESET PASSWORD</h1>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            value={userResetData.email}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            required
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label">New Password</label>
                        <div className="input-group mb-3">
                            <button type="button" onClick={togglePasswordVisibility} className="btn btn-outline-dark">
                                <FontAwesomeIcon icon={showPassword ? "fa-eye-slash" : "fa-eye"} size="1x" />
                            </button>
                            <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                                id="newPassword"
                                value={userResetData.newPassword}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                required
                            />
                            {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="newPasswordConfirmed" className="form-label">Confirm New Password</label>
                        <div className="input-group mb-3">
                            <button type="button" onClick={togglePasswordConfirmedVisibility} className="btn btn-outline-dark">
                                <FontAwesomeIcon icon={showPasswordConfirmed ? "fa-eye-slash" : "fa-eye"} size="1x" />
                            </button>
                            <input
                                type={showPasswordConfirmed ? "text" : "password"}
                                className={`form-control ${errors.newPasswordConfirmed ? 'is-invalid' : ''}`}
                                id="newPasswordConfirmed"
                                value={userResetData.newPasswordConfirmed}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                required
                            />
                            {errors.newPasswordConfirmed && <div className="invalid-feedback">{errors.newPasswordConfirmed}</div>}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="passwordToken" className="form-label">Password Token from Email</label>
                        <input
                            type="text"
                            className={`form-control ${errors.passwordToken ? 'is-invalid' : ''}`}
                            id="passwordToken"
                            value={userResetData.passwordToken}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            required
                        />
                        {errors.passwordToken && <div className="invalid-feedback">{errors.passwordToken}</div>}
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <button title="cancel" type="button" onClick={onCancelResetPassword} className="btn btn-sm rounded-pill">&nbsp;<FontAwesomeIcon icon="fa-backward-step" size="3x" />&nbsp;</button>
                            <button title="submit" type="submit" className="btn btn-sm rounded-pill" disabled={!isFormValid()}>&nbsp;<FontAwesomeIcon icon="fa-paper-plane" size="3x" />&nbsp;</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PasswordReset;