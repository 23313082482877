import './Profile.css';
import ConfirmModal from '../ConfirmModal/ConfirmModal.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

function Profile({ userProfile, notesCount, onProfileSignOut, onProfileDelete}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showProfileSignOutModal, setShowProfileSignOutModal] = useState(false);
    const [showProfileDeleteModal, setShowProfileDeleteModal] = useState(false);
    const toggleIsExpanded = () => setIsExpanded(!isExpanded);
    
    function daysPassedSinceRolling(date) {
        const inputDate = new Date(date).getTime();
        const currentDate = new Date().getTime();
        const differenceInMs = currentDate - inputDate;
        const daysPassed = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
        return daysPassed;
    }

    function daysPassedSinceCalendar(date) {
        const inputDate = new Date(date);
        const currentDate = new Date();
        inputDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        const differenceInMs = currentDate - inputDate;
        const daysPassed = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
        return daysPassed;
    }

    function handleModalClose() {
        setShowProfileSignOutModal(false);
        setShowProfileDeleteModal(false);
    }

    const revealSignOutModal = () => {
        setShowProfileSignOutModal(true);
    }

    const revealDeleteModal = () => {
        setShowProfileDeleteModal(true);
    }

    return (
        <div>
            <ConfirmModal show={showProfileSignOutModal} onClose={handleModalClose} onConfirm={onProfileSignOut} title="Confirm Sign Out?" message="Are you sure you want to sign out?" ico="fa-right-from-bracket" color="bg-warning" />
            <ConfirmModal show={showProfileDeleteModal} onClose={handleModalClose} onConfirm={onProfileDelete} title="Confirm Delete?" message="Are you sure you want to permanently delete your account?" ico="fa-trash" color="bg-danger" />
            <div className="card text-center bg-secondary mb-3 mt-3">
                <div className="card-header card-profile-header">
                  <button title="profile" onClick={toggleIsExpanded} className="btn btn-lg rounded-pill">
                    <FontAwesomeIcon icon="fa-user" size="3x" />
                  </button>
                </div>
                {isExpanded && 
                <div>
                  <div className="card-body card-profile-body">
                    <div>{}</div>
                    <h6 className="card-text">#{userProfile.id}</h6>
                    <h5 className="card-title display-6 mt-3 mb-4">Welcome, <b>{userProfile.email.split('@')[0]}</b>!</h5>
                    <h6 className="card-text">You spent <b>{daysPassedSinceCalendar(userProfile.createdAt)}</b> days here!</h6>
                    <table className="table table-striped table-light">
                      <tbody>
                        <tr>
                          <td>Notes</td>
                          <td>{notesCount}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{userProfile.email}</td>
                        </tr>
                        <tr>
                          <td>Created</td>
                          <td>{userProfile.createdAt}</td>
                        </tr>
                        {/* <tr>
                          <td>Updated</td>
                          <td>{userProfile.updatedAt}</td>
                        </tr> */}
                      </tbody>
                    </table>
                    <button title="delete" type="button" onClick={revealDeleteModal} className="btn btn-sm rounded-pill"><FontAwesomeIcon icon="fa-trash" size="2x" /></button>
                  </div>
                  <div className="card-footer card-profile-footer text-muted">
                    <div className="row">
                      <div className="col-12">
                          <button title="cancel" type="button" onClick={toggleIsExpanded} className="btn btn-sm rounded-pill">&nbsp;<FontAwesomeIcon icon="fa-backward-step" size="3x" />&nbsp;</button>
                          <button title="sign out" onClick={revealSignOutModal} className="btn rounded-pill"><FontAwesomeIcon icon="fa-right-from-bracket" size="3x" /></button>
                      </div>
                    </div>
                  </div>
                </div>}
            </div>
        </div>
    )
}

export default Profile;
