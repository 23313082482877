import './App.css';

import React, { useState } from 'react';
import Header from './components/Header/Header.js';
import Notebook from './components/Notebook/Notebook.js';
import ScrollToTop from './components/ScrollToTop/ScrollToTop.js';
import Footer from './components/Footer/Footer.js';
import SignUp from './components/SignUp/SignUp.js';
import Login from './components/Login/Login.js';
import Profile from './components/Profile/Profile.js';
import PasswordForget from './components/PasswordForget/PasswordForget.js';
import PasswordReset from './components/PasswordReset/PasswordReset.js';

import { registerUser, loginUser, deleteUser, forgetPassword, resetPassword } from './clients/note-app-server.js';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretUp, faCaretDown, faTrash, faTruckFast, faQuestion, faPeopleRoof, faNotesMedical, faMoneyBill, faGamepad, faHouse, faBriefcase, faCarSide, faPlus, faPlay, faPause, faBan, faCircleCheck, faFileZipper, faFloppyDisk, faBackwardStep, faCircleQuestion, faBeerMugEmpty, faCartShopping, faRotateLeft, faEye, faEyeSlash, faPenToSquare, faRightFromBracket, faUser, faGraduationCap, faHeart, faBasketball, faNoteSticky, faPaperPlane, faLocationDot, faUserGroup, faPizzaSlice, faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons'
library.add(faCaretUp, faCaretDown, faTrash, faTruckFast, faQuestion, faPeopleRoof, faNotesMedical, faMoneyBill, faGamepad, faHouse, faBriefcase, faCarSide, faPlus, faPlay, faPause, faBan, faCircleCheck, faFileZipper, faFloppyDisk, faBackwardStep, faCircleQuestion, faBeerMugEmpty, faCartShopping, faRotateLeft, faEye, faEyeSlash, faPenToSquare, faRightFromBracket, faUser, faGraduationCap, faHeart, faBasketball, faNoteSticky, faPaperPlane, faLocationDot, faUserGroup, faPizzaSlice, faMoneyBillTrendUp);

function App() {
  const [token, setToken] = useState('');
  const [profile, setProfile] = useState({
    id: 0,
    email: '',
    createdAt: null,
    updatedAt: null
  });
  
  const [notesCount, setNotesCount] = useState(0);
  const [isSignUpMenuActive, setIsSignUpMenuActive] = useState(false);
  const [isLoginMenuActive, setIsLoginMenuActive] = useState(false);
  const [isPasswordForgetMenuActive, setIsPasswordForgetMenuActive] = useState(false);
  const [isPasswordResetMenuActive, setIsPasswordResetMenuActive] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState(false);

  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('text-light display-6');

  // VALIDATIONS
  function isTokenValid() {
    return token !== undefined && token !== null && token !== '' && token !== ' ';
  }

  // RESET
  function resetProfile() {
    setProfile({
      id: 0,
      email: '',
      createdAt: null,
      updatedAt: null
    });
  };

  function resetMessage() {
    setMessage('');
    setMessageColor('text-light display-6');
  }

  // MENUS
  const openSignUpMenu = () => {
    resetMessage();
    setIsSignUpMenuActive(true);
    setIsLoginMenuActive(false);
    setIsPasswordForgetMenuActive(false);
    setIsPasswordResetMenuActive(false);
  }
  
  const openLoginMenu = () => {
    resetMessage();
    setIsSignUpMenuActive(false);
    setIsLoginMenuActive(true);
    setIsPasswordForgetMenuActive(false);
    setIsPasswordResetMenuActive(false);
  }

  const openPasswordForgetMenu = () => {
    resetMessage();
    setIsSignUpMenuActive(false);
    setIsLoginMenuActive(false);
    setIsPasswordForgetMenuActive(true);
    setIsPasswordResetMenuActive(false);
  }

  const openPasswordResetMenu = () => {
    resetMessage();
    setIsSignUpMenuActive(false);
    setIsLoginMenuActive(false);
    setIsPasswordForgetMenuActive(false);
    setIsPasswordResetMenuActive(true);
  }

  const closeAllMenus = () => {
    resetMessage();
    setIsSignUpMenuActive(false);
    setIsLoginMenuActive(false);
    setIsPasswordForgetMenuActive(false);
    setIsPasswordResetMenuActive(false);
  }

  // HANDLERS
  const handleSignUp = async (signUpData) => {
    try {
        const response = await registerUser(signUpData);        
        setIsUserRegistered(true);
        closeAllMenus();
        setMessage(response.message);
        setMessageColor('text-warning display-6');        
    } catch (error) {
        console.error('Error signing up user:', error);
        setMessage(`Signing up failed! ${error.response.data.error}`);
        setMessageColor('text-danger display-6');
    }
  };

  const handleLogin = async (loginData) => {
    try {
        const response = await loginUser(loginData);
        setToken(response.token);
        setProfile(response);
        resetMessage();
    } catch (error) {
        console.error('Error logging in user:', error);
        setMessage(`Logging in failed! ${error.response.data.error}`);
        setMessageColor('text-danger display-6');
    }
  };

  const handleSendForgetPassword = async(userForgetData) => {
    try {
      const response = await forgetPassword(userForgetData);
      openPasswordResetMenu();
    } catch (error) {
      console.error('Error forgetting password for user:', error);
      setMessage(`Password forget failed! ${error.response.data.error}`);
      setMessageColor('text-danger display-6');
    }
  };

  const handleResetPassword = async(userResetData) => {
    try {
      const response = await resetPassword(userResetData);        
      closeAllMenus();
      setMessage(response.message);
      setMessageColor('text-success display-6');        
  } catch (error) {
      console.error('Error signing up user:', error);
      setMessage(`Password reset failed! ${error.response.data.error}`);
      setMessageColor('text-danger display-6');
  }
};

  const handleProfileSignOut = async (message = '') => {
    closeAllMenus();
    if (typeof message === 'string' && message !== '') {
      setMessage(message)
      setMessageColor('text-warning display-6');
    }
    setIsUserRegistered(false);
    setToken('');
    resetProfile();
  };

  const handleProfileDelete = async () => {
    try {
      await deleteUser(token);
      handleProfileSignOut();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleCalculateNotesCount = async (notesCount) => {
    setNotesCount(notesCount);
  };

  function handleCancel() {
    closeAllMenus();
  }

  return (
    <div className="App container align-items-center justify-content-center bg-dark text-light">
      <Header />
      <div>
        {!isTokenValid() && !isSignUpMenuActive && <button onClick={openSignUpMenu} className="btn btn-lg rounded-pill m-1 btn-light" disabled={isUserRegistered} >SIGN UP</button>}
        {!isTokenValid() && !isLoginMenuActive && <button onClick={openLoginMenu} className="btn btn-lg rounded-pill m-1 btn-light">LOG IN</button>}
        {isTokenValid() && <Profile userProfile={profile} notesCount={notesCount} onProfileSignOut={handleProfileSignOut} onProfileDelete={handleProfileDelete} />}
        {!isTokenValid() && isSignUpMenuActive && <SignUp onCancelSignUp={handleCancel} onSignUp={handleSignUp} />}
        {!isTokenValid() && isLoginMenuActive && <Login onCancelLogin={handleCancel} onLogin={handleLogin} onForgetPassword={openPasswordForgetMenu} />}
        {!isTokenValid() && isPasswordForgetMenuActive && <PasswordForget onCancelForgetPassword={handleCancel} onSendForgetPassword={handleSendForgetPassword} />}
        {!isTokenValid() && isPasswordResetMenuActive && <PasswordReset onCancelResetPassword={handleCancel} onResetPassword={handleResetPassword} />}
        {message && <p className={messageColor}>{message}</p>}
      </div>
      {isTokenValid() ? <Notebook authToken={token} calculateNotesCount={handleCalculateNotesCount} onJwtExpired={handleProfileSignOut} /> : <div className="text-danger"></div>}
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default App;
