import './Header.css';
import React from 'react';

function Header() {
    return (
        <div>
            <h1 className="display-1">
                <b>NOTES</b>
            </h1>
            <hr />
        </div>
    );
}

export default Header;